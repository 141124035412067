<template>
	<!-- header with logo menu and contact info -->
	<div class="container">
		<div class="row border-bottom mb-4">
			<!-- menu contact -->
			<div class="offset-md-0 col-md-9 my-2">
				<!-- menu -->
				<div class="row contact" v-if="menuItems != undefined">
					<div class="col">
						Koedijk 1, Almelo - 0546 810 888 -
						<a href="mailto:praktijk@dierenartsterkeurs.nl">praktijk@dierenartsterkeurs.nl</a>
					</div>
				</div>

				<!-- contact info -->
				<div class="row my-3">
					<div class="col" v-for="(page, index) in menuItems" :key="index">
						<router-link class="nav" v-if="page.menu_item" :to="page.path == '' ? '/' : page.path">{{
							page.title
						}}</router-link>
					</div>
				</div>
			</div>

			<!-- logo -->
			<div class="col-md-3">
				<a href="/">
					<img src="@/assets/logoDierenartsTerKeurs.jpg" class="img-fluid logo my-2" />
				</a>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data: () => ({
		menuItems: [],
	}),
	props: {
		pages: { type: Array },
	},
	created() {
		this.menuItems = [];
		for (let i = 0; i < this.pages.length; i++) {
			if (this.pages[i].menu_item) {
				this.menuItems.push(this.pages[i]);
			}
		}
	},
};
</script>

<style scoped>
</style>
