<template>
	<div>
		<!-- top image bar -->
		<Hero :propsObj="pages[0]"></Hero>

		<!-- menu header -->
		<Header :pages="pages" />

		<!-- content -->
		<div class="container">
			<div class="row mb-3" v-for="q in quarks" :key="q.id" :class="{ configBgColor: q.configs[0] == 1 }">
				<!-- columns -->
				<!-- for more classes use [`${q.colStyle[index]}`]: true  -->
				<div v-for="(c, index) in q.nrCols" :key="index" :class="q.colStyle[index]">
					<!-- div with content -->
					<div class="h-100" :class="getClass(q, index)">
						<h2 v-if="q.title != ''" v-html="q.title"></h2>
						<h2 v-else>&nbsp;</h2>
						<div id="content" :class="{ 'mx-3': q.configs[index + 1] }" v-html="q.columns[index]"></div>
					</div>
				</div>
				<hr class="w-100" />
			</div>
		</div>
	</div>
</template>

<script>
import Hero from '@/components/Hero';
import Header from '@/components/Header.vue';
import utils from '@/js/utils';

export default {
	props: {
		route: String,
	},

	components: { Hero, Header },

	computed: {
		quarks() {
			let qq = this.$store.quarks;
			// console.log('main.vue computed quarks', qq.length);
			if (qq.length > 0) {
				this.handleQuarks(qq);
				// console.log(qq);
				return qq;
			}
		},
		pages() {
			let pages = this.$store.pages;
			if (pages.length > 0) {
				return pages;
			}
		},
		page() {
			let page = this.$store.getPage(this.route);
			return page;
		},
		classObject: function () {
			return { configColorTop: q.configs[index + 1], colorPurple: q.configs[index + 1] && index == 0 };
		},
	},

	created: function () {
		// console.clear();
		// var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
		// var localISOTime = new Date(Date.now() - tzoffset).toISOString().slice(0, -5).replace('T', ' ');
		// console.log('main', localISOTime);
	},

	methods: {
		handleQuarks(arr) {
			// console.log('arr', arr.length, arr);
			utils.sortByRank(arr);

			let first = true;
			arr.forEach((q) => {
				// determine which column title belongs to
				if (q.title != null) {
					let spl = q.title.split(';');
					if (spl.length == 1) {
						q.titleCol = 0;
					} else {
						q.title = spl[0];
						q.titleCol = spl[1];
					}
				}

				// new property - column styles
				if (!Array.isArray(q.cols)) q.cols = q.cols.split(';');
				q.colStyle = [];
				let i = 0;
				q.cols.forEach((style) => {
					q.colStyle[i] = 'col-md-' + style;
					i++;
				});

				// new property - number of columns
				q.nrCols = q.cols.length;

				// new property - columns as array
				q.columns = [];
				q.columns.push(q.col1);
				q.columns.push(q.col2);
				q.columns.push(q.col3);
				q.columns.push(q.col4);

				// new property - column configuration
				q.configs = [0, 0, 0, 0, 0, 0, 0, 0];
				for (let i = 0; i < q.configs.length; i++) {
					let mask = 1 << i;
					q.configs[i] = (q.config & mask) == mask ? 1 : 0;
				}

				// check for image
				for (let i = 0; i < q.columns.length; i++) {
					// console.log('config', q.config);
					// let el = q.columns[i];
					// if (el != null) {
					// 	if (el.indexOf('<img') != -1) {
					// 		let img = utils.strip_tags(el, '<img>');
					// 		if (q.config && 0x02 << i) q.columns[i] = utils.img_fluid(img);
					// 		if (!first) {
					// 			q.columns[i] = q.columns[i].replace('img-fluid', 'img-fluid yellow-top');
					// 		}
					// 		// console.log('img-fluid', q.columns[i]);
					// 	}
					// }
				}

				first = false;
			});
		},

		getClass(q, index) {
			let obj = {};
			switch (index) {
				case 0:
					obj = { configColorTop: q.configs[index + 1], colorPurple: q.configs[index + 1] };
					break;
				case 1:
					obj = { configColorTop: q.configs[index + 1], colorOrange: q.configs[index + 1] };
					break;
				case 2:
					obj = { configColorTop: q.configs[index + 1], colorBlue: q.configs[index + 1] };
					break;
				case 3:
					obj = { configColorTop: q.configs[index + 1], colorGreen: q.configs[index + 1] };
					break;
			}
			return obj;
		},
	},
};
</script>

<style>
.configBgColor {
	background-color: #fef0d6;
	/* text-align: center; */
}

.configColorTop {
	background-color: #fef0d6;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.colorPurple {
	color: #712281;
	border-top: 16px solid #712281;
}

.colorOrange {
	color: #eba900;
	border-top: 16px solid #eba900;
}

.colorBlue {
	color: #008caa;
	border-top: 16px solid #008caa;
}

.colorGreen {
	color: #abcb59;
	border-top: 16px solid #abcb59;
}

#content {
	display: flex;
	flex-direction: column;
}

#content p img {
	align-self: flex-end;
}
</style>
